import React from 'react';

const AboutQhsse = () => {
  return (
    <div>
      <div className="paddington">
        <div className="row">
          <div className="col-12 col-lg-5 mb-5">
            <div>
              <div className="Aboutvv">About</div>
              <div className="QHSSE">QHSSE</div>
            </div>
          </div>
          <div className="col-12 col-lg-7 mb-5">
            <div className="alltexts">
              The logistics industry operates in a complex and dynamic
              environment with stringent regulations and increasing customer
              expectations. By adopting a Compliance & QHSSE Management System,
              AMT Cameroun s.a. proactively identifies and address potential
              risks to its operations, ensuring the safety of its employees,
              customers, and assets. Additionally, it allows the company to
              monitor and continuously improve its performance in terms of
              quality-of-service delivery while complying with legal
              requirements and ethical standards. Moreover, an efficiently
              implemented QHSSE management system enables AMT Cameroun s.a. to
              demonstrate its commitment to sustainable practices and
              environmental stewardship by reducing waste generation, minimizing
              carbon emissions, and conserving resources - all factors that are
              increasingly important in today's socially responsible business
              landscape. Ultimately, investment in a robust QHSSE management
              system not only safeguards the company's reputation but also
              creates added value for its stakeholders by promoting operational
              excellence and corporate sustainability. <br /> <br />{' '}
              <div className="assembly">QHSSE Management System</div> <br /> AMT
              Cameroun s.a. is committed to ensuring the highest standards of
              Quality, Health, Safety, Security, and Environmental (QHSSE)
              management across its operations. As an industry leader in the sub
              region, AMT Cameroun s.a. has implemented a robust QHSSE
              management system that adheres strictly to industry best practices
              and international standards. The company places significant
              emphasis on consistently delivering high-quality products and
              services while prioritizing the health and safety of its
              employees, clients, and stakeholders throughout all stages of
              operation. AMT Cameroun s.a. regularly conducts risk assessments
              and audits to identify potential hazards or areas for improvement
              within their procedures, enabling timely preventive actions to be
              taken. Furthermore, the company complies with relevant
              environmental regulations and works towards minimizing its
              ecological footprint by implementing sustainable practices in its
              day-to-day operations. By maintaining a dedicated focus on QHSSE
              excellence, AMT Cameroun s.a. upholds its commitment to providing
              reliable and responsible solutions to its customers while
              contributing positively to the communities it operates in.
              <br /> <br /> <div className="assembly">
                Compliance Program
              </div>{' '}
              <br />
              AMT Cameroun s.a. maintains a robust and well-structured
              compliance program that adheres to international standards such as
              Anti-Bribery and GDPR regulations. The company recognizes the
              importance of conducting business ethically and efficiently, while
              ensuring data privacy and protection. AMT Cameroun s.a.'s
              Anti-Bribery policy is designed to prevent any form of corruption,
              bribery, or improper influence within the organization and
              throughout its business relationships. This policy includes clear
              guidelines for employees and stakeholders, emphasizing the
              prohibition of accepting or giving bribes in any form.
              Additionally, their adherence to GDPR regulations showcases their
              commitment to safeguarding personal data by implementing strict
              control measures on collection, storage, processing, and sharing
              practices. By actively promoting integrity and complying with key
              legal requirements, AMT Cameroun s.a.'s compliance program fosters
              trust among its clients and partners while maintaining a high
              standard of ethical conduct within the organization. <br /> <br />{' '}
              <div className="assembly">Contact Information: </div> <br /> Do
              not hesitate to contact us if you feel the need at compliance &
              QHSE department: E-mail : compliance@amtcm-sa.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutQhsse;
