import React from 'react';
import Hrader from '../component/Hrader';
import Mission from '../component/Mission';
import Services from '../component/Services';
import Location from '../component/Location';
import News from '../component/News';
import Testimonal from '../component/Testimonal';
import LocalCert from '../component/LocalCert';
import Faq from '../component/Faq';

const Home = () => {
  return (
    <div style={{ position: 'relative' }}>
      <Hrader />
      <Mission />
      <Services />
      <Location />
      <News />
      <Testimonal />
      <LocalCert />
      <Faq />
      <div className="krkrk">
        <a href="https://wa.me/237679523690" target="_blank" rel="noopener noreferrer">
        <div className="whatsapp_circle">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.9992 5.18359C15.3975 5.18359 5.1825 15.3986 5.1825 28.0003C5.1825 32.3126 6.38037 36.3512 8.46353 39.7919L6.42829 46.7099C6.31143 47.1071 6.30374 47.5285 6.40603 47.9297C6.50831 48.3309 6.7168 48.6971 7.00956 48.9899C7.30233 49.2826 7.66856 49.4911 8.06975 49.5934C8.47095 49.6957 8.8923 49.688 9.2895 49.5711L16.2075 47.5359C19.7639 49.6877 23.8425 50.8226 27.9992 50.8169C40.6008 50.8169 50.8158 40.6019 50.8158 28.0003C50.8158 15.3986 40.6008 5.18359 27.9992 5.18359ZM22.838 33.1637C27.4538 37.7772 31.8597 38.3864 33.4158 38.4434C35.7819 38.5302 38.0864 36.7231 38.9831 34.6262C39.0963 34.3654 39.1374 34.0789 39.1021 33.7968C39.0668 33.5146 38.9563 33.2471 38.7823 33.0222C37.532 31.425 35.8413 30.2774 34.1893 29.1365C33.8444 28.8981 33.4206 28.8024 33.0067 28.8696C32.5928 28.9368 32.221 29.1617 31.9693 29.497L30.6003 31.5848C30.5283 31.697 30.4161 31.7775 30.2868 31.8097C30.1574 31.842 30.0206 31.8235 29.9044 31.7582C28.9757 31.2265 27.6227 30.323 26.6507 29.351C25.6787 28.379 24.8299 27.0876 24.3531 26.2183C24.2939 26.1078 24.2768 25.9797 24.3049 25.8576C24.3331 25.7355 24.4045 25.6278 24.5059 25.5543L26.6142 23.9891C26.9151 23.7275 27.1092 23.3644 27.1597 22.9689C27.2102 22.5734 27.1135 22.1731 26.888 21.8443C25.8658 20.3476 24.6748 18.4446 22.9476 17.1829C22.7246 17.0213 22.4635 16.9206 22.1899 16.8906C21.9162 16.8606 21.6395 16.9024 21.3869 17.0118C19.2878 17.9107 17.4716 20.2152 17.5583 22.5859C17.6153 24.142 18.2245 28.5479 22.838 33.1637Z"
              fill="#349E32"
            />
          </svg>
        </div>
          </a>
      </div>
    </div>
  );
};

export default Home;
