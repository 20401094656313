import React from 'react';

const JBody = () => {
  return (
    <div className="container">
      <div className="jibow">
        <div className="jbno">
          <span>Job Description</span>
        </div>
        <div className="ennough">
          <div className="mb-4">
            With Ubuntu, Canonical created the first Linux for human beings. Our
            design team is on a mission to turn complex, open-source software
            into intuitive products that can change the face of enterprise IT.
            We are looking for an exceptional senior user experience designer,
            who shares our passion. Our mission is to make enterprise-grade open
            source software, applications and services available to people
            everywhere. We create world-renowned software, impacting the lives
            of millions of engineers, enterprises, entrepreneurs and consumers
            every day. Our multi-disciplinary web design and production team
            includes visual designers, UX designers, front-end and back-end
            developers to bring exciting web projects to life. We help and learn
            from each other and strive to improve our work and processes. We
            value
          </div>
          <div className="mb-4">
            <ul>
              <li>Excellent academic results at school and university</li>
            </ul>
            <ul>
              <li>
                Bachelor's or equivalent in User Experience, Design, or STEM
              </li>
            </ul>
            <ul>
              <li>
                Knowledge and passion for user experience, technology and design
              </li>
            </ul>
            <ul>
              <li>
                Commitment to continuous learning and improvement - curious,
                flexible, scientific
              </li>
            </ul>
            <ul>
              <li>Drive to finish work and celebrate success</li>
            </ul>
            <ul>
              <li>Confidence in user research practices</li>
            </ul>
            <ul>
              <li>Engagement with the latest design research and innovation</li>
            </ul>
          </div>
          <div className="mb-4">
            UX designers should be excellent communicators who are at ease
            working with a complex mix of engineers, designers and executives.
            They represent us at cross-functional company meetings and must
            present their design position with clarity and precision. They
            should be confident and excited to take on complex design
            challenges. They should also be able to connect their design
            leadership to the strategic vision of the company.Read more about
            the design teamWhat we offer:
          </div>
          <div className="mb-4">
            <ul>
              <li>An opportunity to shape a company and products</li>
            </ul>
            <ul>
              <li>Influence in the open source community</li>
            </ul>
            <ul>
              <li>An inclusive work environment</li>
            </ul>
            <ul>
              <li>Learning and development</li>
            </ul>
            <ul>
              <li>Annual compensation review</li>
            </ul>
            <ul>
              <li>Recognition rewards</li>
            </ul>
            <ul>
              <li>Annual leave</li>
            </ul>
            <ul>
              <li>Priority Pass for travel</li>
            </ul>

            <span>
              Canonical is a growing international software company that works
              with the open-source community to deliver Ubuntu, the world's best
              free software platform. Our services help businesses worldwide
              reduce costs, improve efficiency and enhance security with Ubuntu
            </span>
          </div>

          <div className="fill">
            <span>Fill out this form to apply</span>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-lg-6 mb-5">
              <div>
                <div className="labelll">First Name</div>
                <input type="text" placeholder="" className="quessde" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-5">
              <div>
                <div className="labelll">Last Name</div>
                <input type="text" placeholder="" className="quessde" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-5">
              <div>
                <div className="labelll">Email</div>
                <input type="email" placeholder="" className="quessde" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-5">
              <div>
                <div className="labelll">Phone Number</div>
                <input type="phone" placeholder="" className="quessde" />
              </div>
            </div>

            <div className="col-12  mb-5">
              <div className="uploadsdd">
                <div className="labalaba">
                  <div>
                    <span>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 17V10.25M12 10.25L15 13.25M12 10.25L9.00001 13.25M6.75001 20C5.67944 20.0012 4.64353 19.6206 3.82831 18.9267C3.01309 18.2328 2.47196 17.2709 2.30212 16.2139C2.13227 15.1569 2.34484 14.074 2.90163 13.1596C3.45842 12.2452 4.32297 11.5593 5.34001 11.225C5.07871 9.88618 5.34877 8.49822 6.09299 7.35503C6.83722 6.21185 7.99718 5.40321 9.32723 5.10037C10.6573 4.79752 12.053 5.02425 13.2188 5.73253C14.3846 6.44081 15.2289 7.57504 15.573 8.89501C16.105 8.72199 16.6748 8.70117 17.218 8.83491C17.7612 8.96865 18.2562 9.25162 18.6471 9.65185C19.038 10.0521 19.3092 10.5536 19.43 11.0999C19.5509 11.6461 19.5166 12.2152 19.331 12.743C20.1497 13.0557 20.833 13.6451 21.2626 14.409C21.6921 15.1728 21.8407 16.0629 21.6826 16.9249C21.5245 17.7868 21.0697 18.5663 20.3971 19.128C19.7244 19.6897 18.8763 19.9982 18 20H6.75001Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>{' '}
                    <span>Upload CV/Resume Google Drive, Dropbox, </span>
                  </div>
                  <div>
                    <button className="shiba"> Upload CV </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div>
                <div className="labelll">Personal Summary (Optional)</div>
                <textarea className="textts" />
              </div>
            </div>
            <div className="mb-4">
              <button className="contact_us">Apply Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JBody;
