import React from 'react';
import e from '../image/ecl.svg';
import k from '../image/kard1.png';
import i from '../image/image 98.png';
import { Link } from 'react-router-dom';
const NewsDet = ({ newsItem }) => {
  return (
    <div className="doodr">
      <div className="container">
        <div className="pembass">
          <span>{newsItem?.title}</span>
        </div>
        <div className="eurjr">
          <div className="ejoke">
            <div>
              <div className="eowirudcu">
                <div className="fkfk">
                  <img src={e} alt="" />
                </div>
                <div>
                  <div className="nameeeee">Amt logistics</div>
                  <div className="minnn">20 Jan 2022 </div>
                </div>
              </div>
            </div>
            <div className="weedx">
              <div>
                <span className="mx-4">Share on:</span>
                <span>
                  <svg
                    width="169"
                    height="27"
                    viewBox="0 0 169 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="26.9091"
                      height="26.9091"
                      transform="translate(0.681641)"
                      fill="white"
                    />
                    <path
                      d="M26.4689 3.36308C25.3952 4.12043 24.2064 4.69969 22.9483 5.07854C22.273 4.30211 21.3756 3.7518 20.3774 3.50203C19.3792 3.25226 18.3283 3.31509 17.367 3.68201C16.4057 4.04894 15.5802 4.70226 15.0023 5.55361C14.4243 6.40497 14.1218 7.41328 14.1356 8.44217V9.56338C12.1652 9.61448 10.2128 9.17748 8.45217 8.29131C6.69156 7.40514 5.17745 6.09731 4.04467 4.48429C4.04467 4.48429 -0.440178 14.5752 9.65073 19.0601C7.34163 20.6275 4.59088 21.4134 1.80225 21.3025C11.8932 26.9085 24.2265 21.3025 24.2265 8.40854C24.2255 8.09623 24.1954 7.78469 24.1368 7.47793C25.2811 6.34942 26.0886 4.92461 26.4689 3.36308Z"
                      fill="#121212"
                    />
                    <rect
                      width="26.9091"
                      height="26.9091"
                      transform="translate(47.5908)"
                      fill="white"
                    />
                    <path
                      d="M67.7728 2.24292H64.4092C62.9223 2.24292 61.4964 2.83356 60.4451 3.8849C59.3937 4.93624 58.8031 6.36216 58.8031 7.84898V11.2126H55.4395V15.6975H58.8031V24.6672H63.2879V15.6975H66.6516L67.7728 11.2126H63.2879V7.84898C63.2879 7.55162 63.4061 7.26643 63.6163 7.05616C63.8266 6.8459 64.1118 6.72777 64.4092 6.72777H67.7728V2.24292Z"
                      fill="#121212"
                    />
                    <rect
                      width="26.9091"
                      height="26.9091"
                      transform="translate(94.5)"
                      fill="white"
                    />
                    <path
                      d="M112.44 8.97021C114.224 8.97021 115.935 9.67898 117.197 10.9406C118.458 12.2022 119.167 13.9133 119.167 15.6975V23.546H114.682V15.6975C114.682 15.1028 114.446 14.5324 114.025 14.1119C113.605 13.6913 113.034 13.4551 112.44 13.4551C111.845 13.4551 111.275 13.6913 110.854 14.1119C110.434 14.5324 110.197 15.1028 110.197 15.6975V23.546H105.712V15.6975C105.712 13.9133 106.421 12.2022 107.683 10.9406C108.944 9.67898 110.655 8.97021 112.44 8.97021Z"
                      fill="#121212"
                    />
                    <path
                      d="M101.229 10.0908H96.7437V23.5454H101.229V10.0908Z"
                      fill="#121212"
                    />
                    <path
                      d="M98.9861 6.72777C100.225 6.72777 101.229 5.7238 101.229 4.48534C101.229 3.24689 100.225 2.24292 98.9861 2.24292C97.7476 2.24292 96.7437 3.24689 96.7437 4.48534C96.7437 5.7238 97.7476 6.72777 98.9861 6.72777Z"
                      fill="#121212"
                    />
                    <rect
                      width="26.9091"
                      height="26.9091"
                      transform="translate(141.409)"
                      fill="white"
                    />
                    <path
                      d="M160.469 2.24292H149.257C146.161 2.24292 143.651 4.75284 143.651 7.84898V19.0611C143.651 22.1572 146.161 24.6672 149.257 24.6672H160.469C163.565 24.6672 166.075 22.1572 166.075 19.0611V7.84898C166.075 4.75284 163.565 2.24292 160.469 2.24292Z"
                      fill="#121212"
                    />
                    <path
                      d="M159.348 12.7486C159.486 13.6818 159.327 14.6348 158.892 15.4721C158.458 16.3094 157.771 16.9884 156.928 17.4125C156.085 17.8366 155.13 17.9842 154.199 17.8344C153.268 17.6845 152.407 17.2448 151.74 16.5778C151.073 15.9107 150.634 15.0503 150.484 14.119C150.334 13.1876 150.481 12.2328 150.906 11.3901C151.33 10.5475 152.009 9.8601 152.846 9.42563C153.683 8.99116 154.636 8.83178 155.569 8.97015C156.521 9.11129 157.402 9.55482 158.083 10.2352C158.763 10.9156 159.207 11.7968 159.348 12.7486Z"
                      fill="white"
                    />
                    <path
                      d="M161.029 7.28833H161.041"
                      stroke="white"
                      stroke-width="2.01818"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container helaiiu">
          <div className="weiuhg">
            <img src={newsItem?.imageUrl || i} alt="" />
          </div>
        </div>

        <div className="eurjr2">
          <div
            dangerouslySetInnerHTML={{
              __html: newsItem?.content,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewsDet;
