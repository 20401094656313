import React from 'react';
import c from '../image/cargos.svg';
import t from '../image/trucks.svg';
import d from '../image/Photo.png';
import cu from '../image/custom.svg';
import ll from '../image/landd.webp';
import cr from '../image/crane.svg';
import mee from '../image/hh.png';
import LocalCertSecond from './LocalCertSecond';
const International = () => {
  return (
    <div className="">
      <div className="container">
        <div className="innerhouse">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted">
                <div className="inttt">
                  <span>
                    AIR, SEA, LAND, MULTI MODAL (Import & Export), Consolidation
                    (LCL), Door to-door delivery
                  </span>
                </div>
                <div className="sofyun">
                  <span>
                    We provide end-to-end logistics services ensuring goods
                    arrive at the right time and at the right place via AIR,
                    SEA, or RAIL. We quickly respond to market demand ensuring
                    traceability of cargoes throughout their journey. For
                    regular small quantities that don’t need the speed of
                    airfreight; LCL is one of the perfect option. Our LCL
                    service offered by our principals (NVOCCs and freight
                    forwarders) who consolidate the different shipments into one
                    container and the send this to Douala. Once container
                    arrives at the port, we handle the transfer, warehousing and
                    storage, deconsolidation informing the clients.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4 d-flex align-items-center">
              <div className="wellop">
                <img src={c} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className="innerhouse bluup">
        <div className="container">
          <div className="row willeo">
            <div className="col-12 col-lg-6 mb-4">
              <div className="wellop">
                <img src={cu} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted2">
                <div className="inttt2">
                  <span>Custom clearance</span>
                </div>
                <div className="sofyun2">
                  <span>
                    Our Customs specialists manage all processes related to
                    customs clearance of cargo, vessel and aircraft in
                    accordance with applicable customs regulations in Cameroon
                    Pre-shipment coordination and overall paperwork review in
                    accordance with origin or destination, freight rules to
                    ensure smooth and cost-effective delivery up to requested
                    final destination - Process financial ad suspensive customs
                    regimes of cargo, vessel and aircraft - Accurate estimation
                    of duties and taxes to be paid - Payment of taxes on your
                    behalf, when necessary - Use of AMT Customs bond
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="innerhouse">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted3">
                <div className="inttt">
                  <span>
                    Land transport on national territory-hinterland (Central
                    Africa sub-region){' '}
                  </span>
                </div>
                <div className="sofyun">
                  <span>
                    We deliver shipments (Container, RORO, breakbulk, hazardous
                    cargo) in the CEMAC subregions such as Tchad, CAR, Congo,
                    Gabon, EG both on DDU and DDP basis
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="wellop">
                <img src={ll} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className="innerhouse bluup">
        <div className="container">
          <div className="row willeo">
            <div className="col-12 col-lg-6 mb-4">
              <div className="wellop">
                <img src={t} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted2">
                <div className="inttt2">
                  <span>Logistics Base Management</span>
                </div>
                <div className="sofyun2">
                  <span>
                    Stock management and handling of cargo, according to
                    international standards and requirements (quay, yard,
                    warehouses). Delivery of cargo (product, equipment) from to
                    airport/ port Storage/ inventory management: offload, load
                    cargo and rental of storage area inside yard and warehouse;
                    follow the entries and the exits to/from the storage area
                    and monitor the quality and the quantities of stored cargo
                    trucks, cranes and forklifts management
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="innerhouse">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted">
                <div className="inttt">
                  <span>Agency</span>
                </div>
                <div className="sofyun">
                  <span>
                    We possess the expertise in providing the following services
                    under Marine:
                    <ul>
                      <li>
                        Port call (ship’s Agency): we organize the commercial
                        and technical port call executing administrative duties
                        that include (not exhaustive) obtaining inward/outward
                        clearances, authorities visits, customs authorization,
                        settlement of port taxes and dues linked to the floating
                        unit. Other services are booking of berths, pilotage,
                        tug assistance arrangements, work permits, shore passes
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Line Management: Here we bill and collect freight,
                        demurrage and detention, follow up of containers fleet,
                        selling freight on behalf of a shipping line.
                      </li>
                    </ul>
                    <ul>
                      <li>
                        We also do Stevedoring: loading , unloading and stowage
                        of cargo on board vessel/ and
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Shore handling: handling of the cargo Onshore Lay-Up
                        solutions and handling: We provide sites solutions for
                        the temporary lay-up vessel and support the client
                        during this operation
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4 d-flex align-items-center">
              <div className="wellop">
                <img src={d} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className="innerhouse bluup">
        <div className="container">
          <div className="row willeo">
            <div className="col-12 col-lg-6 mb-4 d-flex align-items-center">
              <div className="wellop">
                <img src={cr} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted2">
                <div className="inttt2">
                  <span>Personal Support Services</span>
                </div>
                <div className="sofyun2">
                  <span>
                    Reception and assistance For some people, arriving at the
                    airport or port is a source of anxiety or a waste of time.
                    AMT Cameroon has a team at your disposal to offer you a
                    personal VIP service and a professional welcome at airports
                    and ports. This service includes: <br /> <br />
                    <ul>
                      <li> Travel Arrangements- flights/ hotels/ lodging</li>
                    </ul>
                    <ul>
                      <li>
                        {' '}
                        Immigration services- Visas/ work permits/ Letter Of
                        Invitation (LOI)
                      </li>
                    </ul>
                    <ul>
                      <li> Meet and greet services</li>
                    </ul>
                    <ul>
                      <li> Local transport/ transfers/ rentals</li>
                    </ul>
                    <ul>
                      <li>Personnel rotation management</li>
                    </ul>
                    <ul>
                      <li>Local purchasing</li>
                    </ul>
                    <ul>
                      <li>Medical assistance</li>
                    </ul>
                    <ul>
                      <li> Hand carry</li>
                    </ul>
                    <ul>
                      <li> Security services</li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="innerhouse">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <div className="mounted3">
                <div className="inttt">
                  <span>Chartering</span>
                </div>
                <div className="sofyun">
                  <span>
                    Personalised Full and Part Chartering of Cargo Aircraft,
                    Breakbulk, Barges, Tugs & Supply Boats around the world.
                    <br /> <br />
                    <ul>
                      <li>
                        Multi-modal transport solutions for cost economy,
                        ocean/air, road/air, rail/air
                      </li>
                    </ul>
                    <ul>
                      <li>
                        {' '}
                        Proposing different solutions depending on time lines,
                        also using co-load
                      </li>
                    </ul>
                    <ul>
                      <li> possibilities for cost economy</li>
                    </ul>
                    <ul>
                      <li> Advantage of space commitments</li>
                    </ul>
                    <ul>
                      <li>Barge transportation</li>
                    </ul>
                    <ul>
                      <li>Access to large variety of vessel types</li>
                    </ul>
                    <ul>
                      <li>Medical assistance</li>
                    </ul>
                    <ul>
                      <li>
                        Ocean carriage by multi-purpose, heavy lift and RoRo
                        vessels
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <div
                className="wellop"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <img src={mee} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <LocalCertSecond />
    </div>
  );
};

export default International;
