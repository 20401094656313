import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { db, storage } from '../firebase'; // import storage from firebase configuration
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid'; // Import uuid

const PostNews = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (value) => {
    setContent(value);
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedImage(file);
      setImageURL(URL.createObjectURL(file));
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!title || !content || !selectedImage) {
      alert('Please fill all fields and choose an image');
      setLoading(false);
      return;
    }

    try {
      const storageRef = ref(storage, `news_images/${selectedImage.name}`);
      await uploadBytes(storageRef, selectedImage);
      const url = await getDownloadURL(storageRef);

      const newsDocRef = doc(db, 'news', 'news');
      await updateDoc(newsDocRef, {
        allNews: arrayUnion({
          id: uuidv4(), // Generate unique ID for the news item
          title,
          content,
          imageUrl: url,
        }),
      });

      alert('News posted successfully!');
      setTitle('');
      setContent('');
      setSelectedImage(null);
      setImageURL('');
      setLoading(false);
    } catch (error) {
      console.error('Error uploading news: ', error);
      alert('Failed to post news.');
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="loose">
        <div className="container">
          <div className="favzz">Post News</div>

          <div className="d-flex justify-content-center w-100">
            <div className="ahhh">
              <div className="spiritt">
                {imageURL && <img src={imageURL} alt="Selected" />}
              </div>
              <div className="d-flex justify-content-center mt-3">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  id="file-input"
                  style={{ display: 'none' }}
                />
                <label htmlFor="file-input" className="send_button3">
                  Choose Image
                </label>
              </div>
              <div className="row" style={{ marginTop: '80px' }}>
                <div className="col-12 mb-5">
                  <div>
                    <div className="labelll">Blog Title</div>
                    <input
                      type="text"
                      placeholder=""
                      className="quessde"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 mb-5">
                  <div>
                    <div className="labelll">Blog Content</div>
                    <ReactQuill value={content} onChange={handleChange} />
                  </div>
                </div>
                <div>
                  <button
                    className="send_button2"
                    onClick={handleUpload}
                    disabled={loading || !content || !title}
                  >
                    {loading ? 'Posting news...' : 'Post News'}
                  </button>
                </div>
                {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostNews;
