import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

// import f3 from '../image/career.svg';
// import f2 from '../image/career.svg';
// import f4 from '../image/career.svg';

const JDHead = () => {
  return (
    <div className="">
      <div className="sheiriri">
        <div className="container">
          <div className="container">
            <div className="container">
              <div className="boggy2">
                <div className="candara2">
                  <span>UI/UX Designer</span>
                </div>
                <div className="empowering22">
                  <span>
                    In this enchanting role, you will be responsible for
                    designing stunning user experiences and shaping breathtaking
                    interfaces. You will be able to unleash your creativity and
                    collaborate with our team of brilliant 🧠 designers
                  </span>
                </div>
                <div className="onsite2 mt-4">
                  <span>
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_267_4677)">
                        <path
                          d="M17.5 8.83334C17.5 14.6667 10 19.6667 10 19.6667C10 19.6667 2.5 14.6667 2.5 8.83334C2.5 6.84421 3.29018 4.93656 4.6967 3.53003C6.10322 2.12351 8.01088 1.33334 10 1.33334C11.9891 1.33334 13.8968 2.12351 15.3033 3.53003C16.7098 4.93656 17.5 6.84421 17.5 8.83334Z"
                          stroke="#ffffff"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 11.3333C11.3807 11.3333 12.5 10.214 12.5 8.83334C12.5 7.45262 11.3807 6.33334 10 6.33334C8.61929 6.33334 7.5 7.45262 7.5 8.83334C7.5 10.214 8.61929 11.3333 10 11.3333Z"
                          stroke="#ffffff"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_267_4677">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>{' '}
                  <span>On-Site, Do</span>
                </div>
                <div className="mt-4">
                  <button className="contact_us">Apply Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JDHead;
