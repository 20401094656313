import React from 'react';
import { Link } from 'react-router-dom';

const AClass = () => {
  return (
    <div className="container">
      <div className="mt-4 mb-4">
        <div className="join">
          <span>Join the A-Class team for go-getters.</span>
        </div>
        <div className="Explore">
          <span>
            Explore client interaction or focus on programming and research at
            AMT. Apply for job vacancies now!
          </span>
        </div>
        <div className="jamu">
          <div className="benedde">
            <div className="fullot">
              <div className="asedeygo">
                <div>
                  <div className="Douala">
                    <span>Douala, Cameroon</span>
                  </div>
                  <div className="wory">
                    <span>UI/UX Designer</span>
                  </div>
                </div>
                <Link
                  to="/job-description"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="View">
                    View job{' '}
                    <span>
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.833496 9.66667L9.16683 1.33333M9.16683 1.33333H0.833496M9.16683 1.33333V9.66667"
                          stroke="#4EBCD7"
                          stroke-width="1.67"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="downiee">
                <div className="d-flex">
                  <div className="onsite">
                    <span>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_267_4677)">
                          <path
                            d="M17.5 8.83334C17.5 14.6667 10 19.6667 10 19.6667C10 19.6667 2.5 14.6667 2.5 8.83334C2.5 6.84421 3.29018 4.93656 4.6967 3.53003C6.10322 2.12351 8.01088 1.33334 10 1.33334C11.9891 1.33334 13.8968 2.12351 15.3033 3.53003C16.7098 4.93656 17.5 6.84421 17.5 8.83334Z"
                            stroke="#D9D9D9"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 11.3333C11.3807 11.3333 12.5 10.214 12.5 8.83334C12.5 7.45262 11.3807 6.33334 10 6.33334C8.61929 6.33334 7.5 7.45262 7.5 8.83334C7.5 10.214 8.61929 11.3333 10 11.3333Z"
                            stroke="#D9D9D9"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_267_4677">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>{' '}
                    <span>On-Site</span>
                  </div>
                  <div className="onsite mx-3">
                    <span>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99984 4.5V9.5L13.3332 11.1667M18.3332 9.5C18.3332 14.1024 14.6022 17.8333 9.99984 17.8333C5.39746 17.8333 1.6665 14.1024 1.6665 9.5C1.6665 4.89762 5.39746 1.16666 9.99984 1.16666C14.6022 1.16666 18.3332 4.89762 18.3332 9.5Z"
                          stroke="#D9D9D9"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>{' '}
                    <span>On-Site</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="fullot">
              <div className="asedeygo">
                <div>
                  <div className="Douala">
                    <span>Douala, Cameroon</span>
                  </div>
                  <div className="wory">
                    <span>UI/UX Designer</span>
                  </div>
                </div>

                <Link
                  to="/job-description"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="View">
                    View job{' '}
                    <span>
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.833496 9.66667L9.16683 1.33333M9.16683 1.33333H0.833496M9.16683 1.33333V9.66667"
                          stroke="#4EBCD7"
                          stroke-width="1.67"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="downiee">
                <div className="d-flex">
                  <div className="onsite">
                    <span>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_267_4677)">
                          <path
                            d="M17.5 8.83334C17.5 14.6667 10 19.6667 10 19.6667C10 19.6667 2.5 14.6667 2.5 8.83334C2.5 6.84421 3.29018 4.93656 4.6967 3.53003C6.10322 2.12351 8.01088 1.33334 10 1.33334C11.9891 1.33334 13.8968 2.12351 15.3033 3.53003C16.7098 4.93656 17.5 6.84421 17.5 8.83334Z"
                            stroke="#D9D9D9"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 11.3333C11.3807 11.3333 12.5 10.214 12.5 8.83334C12.5 7.45262 11.3807 6.33334 10 6.33334C8.61929 6.33334 7.5 7.45262 7.5 8.83334C7.5 10.214 8.61929 11.3333 10 11.3333Z"
                            stroke="#D9D9D9"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_267_4677">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>{' '}
                    <span>On-Site</span>
                  </div>
                  <div className="onsite mx-3">
                    <span>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99984 4.5V9.5L13.3332 11.1667M18.3332 9.5C18.3332 14.1024 14.6022 17.8333 9.99984 17.8333C5.39746 17.8333 1.6665 14.1024 1.6665 9.5C1.6665 4.89762 5.39746 1.16666 9.99984 1.16666C14.6022 1.16666 18.3332 4.89762 18.3332 9.5Z"
                          stroke="#D9D9D9"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>{' '}
                    <span>On-Site</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="fullot">
              <div className="asedeygo">
                <div>
                  <div className="Douala">
                    <span>Douala, Cameroon</span>
                  </div>
                  <div className="wory">
                    <span>UI/UX Designer</span>
                  </div>
                </div>

                <Link
                  to="/job-description"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="View">
                    View job{' '}
                    <span>
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.833496 9.66667L9.16683 1.33333M9.16683 1.33333H0.833496M9.16683 1.33333V9.66667"
                          stroke="#4EBCD7"
                          stroke-width="1.67"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="downiee">
                <div className="d-flex">
                  <div className="onsite">
                    <span>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_267_4677)">
                          <path
                            d="M17.5 8.83334C17.5 14.6667 10 19.6667 10 19.6667C10 19.6667 2.5 14.6667 2.5 8.83334C2.5 6.84421 3.29018 4.93656 4.6967 3.53003C6.10322 2.12351 8.01088 1.33334 10 1.33334C11.9891 1.33334 13.8968 2.12351 15.3033 3.53003C16.7098 4.93656 17.5 6.84421 17.5 8.83334Z"
                            stroke="#D9D9D9"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 11.3333C11.3807 11.3333 12.5 10.214 12.5 8.83334C12.5 7.45262 11.3807 6.33334 10 6.33334C8.61929 6.33334 7.5 7.45262 7.5 8.83334C7.5 10.214 8.61929 11.3333 10 11.3333Z"
                            stroke="#D9D9D9"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_267_4677">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>{' '}
                    <span>On-Site</span>
                  </div>
                  <div className="onsite mx-3">
                    <span>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99984 4.5V9.5L13.3332 11.1667M18.3332 9.5C18.3332 14.1024 14.6022 17.8333 9.99984 17.8333C5.39746 17.8333 1.6665 14.1024 1.6665 9.5C1.6665 4.89762 5.39746 1.16666 9.99984 1.16666C14.6022 1.16666 18.3332 4.89762 18.3332 9.5Z"
                          stroke="#D9D9D9"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>{' '}
                    <span>On-Site</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="fullot">
              <div className="asedeygo">
                <div>
                  <div className="Douala">
                    <span>Douala, Cameroon</span>
                  </div>
                  <div className="wory">
                    <span>UI/UX Designer</span>
                  </div>
                </div>

                <Link
                  to="/job-description"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="View">
                    View job{' '}
                    <span>
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.833496 9.66667L9.16683 1.33333M9.16683 1.33333H0.833496M9.16683 1.33333V9.66667"
                          stroke="#4EBCD7"
                          stroke-width="1.67"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="downiee">
                <div className="d-flex">
                  <div className="onsite">
                    <span>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_267_4677)">
                          <path
                            d="M17.5 8.83334C17.5 14.6667 10 19.6667 10 19.6667C10 19.6667 2.5 14.6667 2.5 8.83334C2.5 6.84421 3.29018 4.93656 4.6967 3.53003C6.10322 2.12351 8.01088 1.33334 10 1.33334C11.9891 1.33334 13.8968 2.12351 15.3033 3.53003C16.7098 4.93656 17.5 6.84421 17.5 8.83334Z"
                            stroke="#D9D9D9"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 11.3333C11.3807 11.3333 12.5 10.214 12.5 8.83334C12.5 7.45262 11.3807 6.33334 10 6.33334C8.61929 6.33334 7.5 7.45262 7.5 8.83334C7.5 10.214 8.61929 11.3333 10 11.3333Z"
                            stroke="#D9D9D9"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_267_4677">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>{' '}
                    <span>On-Site</span>
                  </div>
                  <div className="onsite mx-3">
                    <span>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99984 4.5V9.5L13.3332 11.1667M18.3332 9.5C18.3332 14.1024 14.6022 17.8333 9.99984 17.8333C5.39746 17.8333 1.6665 14.1024 1.6665 9.5C1.6665 4.89762 5.39746 1.16666 9.99984 1.16666C14.6022 1.16666 18.3332 4.89762 18.3332 9.5Z"
                          stroke="#D9D9D9"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>{' '}
                    <span>On-Site</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AClass;
